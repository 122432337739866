import {createMemoryHistory, createRouter, createWebHashHistory, createWebHistory} from "vue-router";
import GridMenu from "@/views/newstudent/GridMenu.vue";
import Submission from "@/views/newstudent/Submission.vue";
import SubmissionsHistoryView from "@/views/newstudent/SubmissionsHistory.vue";
import AppNewStudent from "@/App-NewStudent.vue";
import Submissions from "@/views/secretariat/Submissions.vue";

//TODO studentRouter and secretariatRouter??
const studentRouter = createRouter({
    history: createWebHashHistory(import.meta.env.BASE_URL),
    routes: [
        {
            path: '/',
            name: 'root'
        },
        {
            path: '/logout',
            name: 'logout'
        },
        {
            path: '/menu',
            name: 'GridMenu',
            component: GridMenu
        },
        {
            path: '/new-submission',
            name: 'NewSubmission',
            component: Submission
        },
        {
            path: '/submissions-history',
            name: 'SubmissionsHistory',
            component: SubmissionsHistoryView
        },
        // { path: '/:pathMatch(.*)*', component: PageNotFound },
    ]
})

const secretariatRouter = createRouter({
    history: createWebHashHistory(import.meta.env.BASE_URL),
    routes: [
        {
            path: '/',
            name: 'root'
        },
        {
            path: '/logout',
            name: 'logout'
        },
        {
            path: '/submissions',
            name: 'submissions',
            component: Submissions
        },
    ]
})
export {studentRouter, secretariatRouter}
