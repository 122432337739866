const API_BASE_URI = 'https://sis-apps.unipi.gr/prereg'
const SECRETARIAT_ONLY_SERVICE_PATH_PREFIX = '/intranet'
const HTTP_METHODS = Object.freeze({
    GET: 'GET',
    HEAD: 'HEAD',
    POST: 'POST',
    PUT: 'PUT',
    DELETE: 'DELETE',
    CONNECT: "CONNECT",
    OPTIONS: "OPTIONS",
    TRACE: "TRACE",
    PATCH: "PATCH"
});

const RESPONSE_STATUSES = Object.freeze({
    SUCCESS: 'SUCCESS',
    SUCCESS_WITH_NO_CONTENT: 'SUCCESS_WITH_NO_CONTENT',
    BAD_REQUEST: "BAD_REQUEST",
    AUTHENTICATION_FAILED: 'AUTHENTICATION_FAILED',
    TOO_MANY_REQUESTS: 'TOO_MANY_REQUESTS',
    SERVER_ERROR: 'SERVER_ERROR',
    UNEXPECTED_ERROR: 'UNEXPECTED_ERROR',
    LOCKED_RESOURCE: 'LOCKED_RESOURCE',
    CONFLICT: 'CONFLICT'
})

const REQUEST_BODY_TYPE = {
    JSON: "JSON",
    FORM_DATA: "FORM_DATA"
}

const RESPONSE_BODY_REPRESENTATION = {
    JSON: "JSON",
    BLOB: "BLOB",
    ARRAY_BUFFER: "ARRAY_BUFFER",
    TEXT: "TEXT"

}

// TODO ADD DTOs with null default values
class BaseAPI {
    static get RESPONSE_STATUSES() {
        return RESPONSE_STATUSES;
    }

    static APIResponse = class {
        constructor(responseStatus, body = null) {
            this.responseStatus = responseStatus;
            this.body = body;
        }
    }

    static async _doHttpRequest(httpMethod, url, responseBodyRepresentation, token = null, requestBody = null, requestBodyType = null) {
        let headers = {}
        if (requestBodyType === REQUEST_BODY_TYPE.JSON) {
            headers['Content-Type'] = 'application/json'
        }

        if (token) {
            headers['Authorization'] = `jwt ${token}`
        }

        const httpResponse = await fetch(url, {
            method: httpMethod,
            headers: headers,
            body: requestBody,
        })

        if (httpResponse.status === 200) {
            let responseBody;
            if (responseBodyRepresentation === RESPONSE_BODY_REPRESENTATION.JSON) {
                responseBody = await httpResponse.json()
            } else if (responseBodyRepresentation === RESPONSE_BODY_REPRESENTATION.BLOB) {
                responseBody = await httpResponse.blob()
            } else if (responseBodyRepresentation === RESPONSE_BODY_REPRESENTATION.ARRAY_BUFFER) {
                responseBody = await httpResponse.arrayBuffer()
            } else if (responseBodyRepresentation === RESPONSE_BODY_REPRESENTATION.TEXT) {
                responseBody = await httpResponse.text()
            } else {
                responseBody = httpResponse.body
            }
            return new BaseAPI.APIResponse(BaseAPI.RESPONSE_STATUSES.SUCCESS, responseBody);
        } else if (httpResponse.status === 204) {
            return new BaseAPI.APIResponse(BaseAPI.RESPONSE_STATUSES.SUCCESS_WITH_NO_CONTENT);
        } else if (httpResponse.status === 400) {
            return new BaseAPI.APIResponse(BaseAPI.RESPONSE_STATUSES.BAD_REQUEST);
        } else if (httpResponse.status === 401) {
            return new BaseAPI.APIResponse(BaseAPI.RESPONSE_STATUSES.AUTHENTICATION_FAILED);
        } else if (httpResponse.status === 403) {
            return new BaseAPI.APIResponse(BaseAPI.RESPONSE_STATUSES.AUTHENTICATION_FAILED);
        } else if (httpResponse.status === 409) {
            return new BaseAPI.APIResponse(BaseAPI.RESPONSE_STATUSES.CONFLICT);
        } else if (httpResponse.status === 423) {
            return new BaseAPI.APIResponse(BaseAPI.RESPONSE_STATUSES.LOCKED_RESOURCE);
        } else if (httpResponse.status === 429) {
            return new BaseAPI.APIResponse(BaseAPI.RESPONSE_STATUSES.TOO_MANY_REQUESTS);
        } else if (httpResponse.status >= 500) {
            return new BaseAPI.APIResponse(BaseAPI.RESPONSE_STATUSES.SERVER_ERROR);
        } else {
            return new BaseAPI.APIResponse(BaseAPI.RESPONSE_STATUSES.UNEXPECTED_ERROR);
        }
    }

    static async _getRequest(url, responseBodyRepresentation, token = null) {
        return await BaseAPI._doHttpRequest(HTTP_METHODS.GET, url, responseBodyRepresentation, token)
    }

    static async _postRequest(url, requestBody, requestBodyType, responseBodyRepresentation, token) {
        return await BaseAPI._doHttpRequest(HTTP_METHODS.POST, url, responseBodyRepresentation, token, requestBody, requestBodyType)
    }

    static async _patchRequest(url, requestBody, requestBodyType, responseBodyRepresentation, token) {
        return await BaseAPI._doHttpRequest(HTTP_METHODS.PATCH, url, responseBodyRepresentation, token, requestBody, requestBodyType)
    }

    static async getSubmissionPeriod() {
        return await BaseAPI._getRequest(`${API_BASE_URI}/submission_period`, RESPONSE_BODY_REPRESENTATION.JSON)
    }

    static async getSubmittedDocument(id, token, fileType) {
        let urlParams = `fileType=${encodeURI(fileType)}`
        return await BaseAPI._getRequest(`${API_BASE_URI}/submissions-documents/${id}?${urlParams}`, RESPONSE_BODY_REPRESENTATION.BLOB, token)
    }
}

class NewStudentAPI extends BaseAPI {

    static async login(username, password) {
        return await BaseAPI._postRequest(
            `${API_BASE_URI}/token-generation`,
            JSON.stringify({username: username, password: password}),
            REQUEST_BODY_TYPE.JSON,
            RESPONSE_BODY_REPRESENTATION.JSON
        )
    }

    static async getLoginOTP(examNumber, email, birthDate) {
        let urlParams = `examNumber=${encodeURI(examNumber)}&email=${encodeURI(email)}&birthDate=${encodeURI(birthDate)}`
        return await BaseAPI._getRequest(`${API_BASE_URI}/otp/new-student?${urlParams}`, RESPONSE_BODY_REPRESENTATION.JSON)
    }

    static async getNewStudentInfo(username, token) {
        let urlParams = `username=${encodeURI(username)}`
        return await BaseAPI._getRequest(`${API_BASE_URI}/users/new-students?${urlParams}`, RESPONSE_BODY_REPRESENTATION.JSON, token)
    }

    static async uploadSupportingDocuments(token, personalDetailsUpdateForm, identity, photo, formalDeclaration, ssn,
                                           copyOfMinistryRegistration, localityCertificate = null, diseaseCertificate = null,
                                           schoolLeavingQualification = null) {
        const formData = new FormData();
        formData.append("personalDetailsUpdateForm", personalDetailsUpdateForm)
        formData.append("identity", identity)
        formData.append("photo", photo)
        formData.append("formalDeclaration", formalDeclaration)
        formData.append("ssn", ssn)
        formData.append("copyOfMinistryRegistration", copyOfMinistryRegistration)

        if (localityCertificate) {
            formData.append("localityCertificate", localityCertificate)
        }
        if (diseaseCertificate) {
            formData.append("diseaseCertificate", diseaseCertificate)
        }
        if (schoolLeavingQualification) {
            formData.append("schoolLeavingQualification", schoolLeavingQualification)
        }

        return await BaseAPI._postRequest(
            `${API_BASE_URI}/submissions`,
            formData,
            REQUEST_BODY_TYPE.FORM_DATA,
            RESPONSE_BODY_REPRESENTATION.JSON,
            token
        )
    }

    static async getPersonalDetailsUpdateFormTemplate(token) {
        return await BaseAPI._getRequest(`${API_BASE_URI}/personalDetailsUpdateFormTemplate`, RESPONSE_BODY_REPRESENTATION.BLOB, token)
    }

}

class SecretariatAPI extends BaseAPI {

    static async login(username, password) {
        return await BaseAPI._postRequest(
            SecretariatAPI.#buildAbsoluteUrl('/token-generation'),
            JSON.stringify({username: username, password: password}),
            REQUEST_BODY_TYPE.JSON,
            RESPONSE_BODY_REPRESENTATION.JSON
        )
    }

    static async getSubmissionsByStatus(departmentId, status, token) {
        let urlParams = `departmentId=${encodeURI(departmentId)}&status=${encodeURI(status)}`
        return await BaseAPI._getRequest(
            SecretariatAPI.#buildAbsoluteUrl(`/submissions?${urlParams}`), RESPONSE_BODY_REPRESENTATION.JSON, token
        )
    }

    static async setSubmissionStatus(submissionId, status, token, statusMetadata = null) {
        let requestBody = {status: status, statusMetadata: statusMetadata}
        return BaseAPI._patchRequest(
            SecretariatAPI.#buildAbsoluteUrl(`/submissions/${submissionId}`),
            JSON.stringify(requestBody), REQUEST_BODY_TYPE.JSON,
            RESPONSE_BODY_REPRESENTATION.JSON, token)
    }

    static async getSecretariatInfo(token) {
        return await BaseAPI._getRequest(
            SecretariatAPI.#buildAbsoluteUrl('/users/secretariats/current'),
            RESPONSE_BODY_REPRESENTATION.JSON,
            token)
    }

    static async resendRegistrationCompletedEmail(submissionId, token) {
        return await BaseAPI._postRequest(
            SecretariatAPI.#buildAbsoluteUrl(`/submissions/${submissionId}/resendRegistrationCompletedEmail`),
            null,
            null,
            null,
            token
        )
    }

    static async resendRejectionEmail(submissionId, token) {
        return await BaseAPI._postRequest(
            SecretariatAPI.#buildAbsoluteUrl(`/submissions/${submissionId}/resendRejectionEmail`),
            null,
            null,
            null,
            token
        )
    }

    static #buildAbsoluteUrl(relativeUrl) {
        if (SECRETARIAT_ONLY_SERVICE_PATH_PREFIX) {
            return `${API_BASE_URI}${SECRETARIAT_ONLY_SERVICE_PATH_PREFIX}${relativeUrl}`
        }
        return `${API_BASE_URI}${relativeUrl}`

    }
}

export {
    BaseAPI
    ,
    NewStudentAPI
    ,
    SecretariatAPI
}