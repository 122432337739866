<script setup>
import {nextTick, onBeforeUnmount, onMounted, ref} from "vue";
import {Modal} from "bootstrap";

const props = defineProps({
      onConfirmCallback: {
        type: Function,
        default: () => {
        }
      }
    }
)

const confirmationModalDom = ref(null)

onMounted(() => {
  Modal.getOrCreateInstance(confirmationModalDom.value) // create instance
})

function onConfirm() {
  props.onConfirmCallback()
  hide()
}

function show() {
  nextTick(() => {
    Modal.getInstance(confirmationModalDom.value).show()
  })
}

function hide() {
  Modal.getInstance(confirmationModalDom.value).hide()
}

onBeforeUnmount(() => {
  Modal.getInstance(confirmationModalDom.value).hide();
  Modal.getInstance(confirmationModalDom.value).dispose();
})


defineExpose({
  show,
  hide
})


</script>

<template>
  <div class="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
       aria-labelledby="staticBackdropLabel" aria-hidden="true" ref="confirmationModalDom">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="staticBackdropLabel">
            <slot name="title"></slot>
          </h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <slot name="body"></slot>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-success" @click="onConfirm">Ναι
          </button>
          <button type="button" class="btn btn-primary" data-bs-dismiss="modal">Όχι</button>
        </div>
      </div>
    </div>
  </div>

</template>

<style scoped>

</style>