<script setup>

import {onMounted, onUnmounted, ref} from 'vue'

const copyrightText = "© 2024 Τμήμα Μηχανοργάνωσης, Πανεπιστήμιο Πειραιώς"
const isFixedBottom = ref(false)
const copyrightDOM = ref(null)


function updatePosition() {
  let bodyHeight = !isFixedBottom ?
      document.body.scrollHeight :  document.body.scrollHeight + copyrightDOM.value.scrollHeight;

  isFixedBottom.value = window.innerHeight > bodyHeight;
}

let resizeObserver = new ResizeObserver(updatePosition)

onMounted(() => {
  window.addEventListener("resize", updatePosition)
  resizeObserver.observe(document.body)
})

onUnmounted(() => {
  window.removeEventListener("resize", updatePosition)
  resizeObserver.disconnect()
})
</script>


<template>
  <footer class="text-center bg-light text-lg-start" :class="{'fixed-bottom': isFixedBottom}" ref="copyrightDOM">
    <div class="text-center p-3">
      {{ copyrightText }}
    </div>
  </footer>
</template>

<style scoped>

</style>