<script setup>
import {useRouter} from 'vue-router'
const router = useRouter();
</script>

<template>
  <div class="container-fluid px-0">
    <div class="row g-0">
      <div class="col px-3 pt-3" style="text-align: justify">
        <div class="container pt-1">
          <div class="d-flex justify-content-center">
            <div class="p-2">
              <button class="btn btn-primary rounded-4"
                      style="width: 15rem; height: 15rem; font-size: 1.5rem;"
                      @click="router.push('/new-submission')"
              >Νέα Αίτηση <br/><i class="bi-clipboard-plus" style="font-size: 1.8rem;"></i></button>
            </div>
            <div class="p-2">
              <button class="btn btn-secondary rounded-4"
                      style="width: 15rem; height: 15rem; font-size: 1.5rem; cursor: pointer"
                      @click="router.push('/submissions-history')">
                Ιστορικό Αιτήσεων <i class="bi-stack" style="font-size: 1.8rem;"></i></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>