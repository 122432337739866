<script setup>
import {computed, onMounted, ref, watch} from "vue"
import {UIMessages} from "@/util/ui-messages"
import {SecretariatAPI} from "@/util/api"
import Loader from "@/components/Loader.vue"
import Toast from "@/components/Toast.vue"
import {TOAST_TYPES} from "@/components/Toast.vue"
import {useSecretariatUserStore} from "@/stores/secretariat-user"
import ConfirmationModal from "@/components/ConfirmationModal.vue"
import {Modal} from "bootstrap"
import SupportingDocumentDownloadLink from "@/components/SupportingDocumentDownloadLink.vue"
import {SupportingDocumentsDefinitions} from "@/util/supporting-documents-definitions"
import {useRoute, useRouter} from "vue-router";
import InformationModal from "@/components/InformationModal.vue";

const isLoaderEnabled = ref(false)
const toastDOM = ref(null)
const toastTitle = ref('')
const toastMessage = ref('');
const toastType = ref(TOAST_TYPES.ERROR_TOAST)
const confirmationModal = ref(null)
const isConfirmationModalDisabled = ref(false)
const isLoadingCompleted = ref(false)
const route = useRoute()
const currentTab = computed(() => route.query.currentTab ? route.query.currentTab : "UNCHECKED")
const router = useRouter()
const rejectionModalDOM = ref(null)
const rejectionReasons = ref("")
const confirmationModalCallback = ref(() => {
})
let rejectionModalCallbackFunction = () => {
}
const informationModal = ref(null)
const informationModalBody = ref('')

const secretariatUserStore = useSecretariatUserStore()
const selectedDepartmentId = ref('')

const submissions = ref([])
const sortedSubmissions = computed(() => submissions.value.toSorted(
    function (a, b) {
      return (new Date(a.timestamp)).getTime() - (new Date(b.timestamp)).getTime()
    }
))

function showErrorToast(message) {
  toastType.value = TOAST_TYPES.ERROR_TOAST
  toastTitle.value = 'Αποτυχία'
  toastMessage.value = message
  toastDOM.value.show()
}

function showSuccessToast(message) {
  toastType.value = TOAST_TYPES.SUCCESS_TOAST
  toastTitle.value = 'Επιτυχής αποστολή αιτήματος'
  toastMessage.value = message
  toastDOM.value.show()
}

function getSubmissionsByStatus(status) {
  isLoaderEnabled.value = true
  isLoadingCompleted.value = false

  SecretariatAPI.getSubmissionsByStatus(selectedDepartmentId.value, status, secretariatUserStore.data.token).then(
      (apiResponse) => {
        if (apiResponse.responseStatus === SecretariatAPI.RESPONSE_STATUSES.SUCCESS) {
          submissions.value = apiResponse.body
          isLoadingCompleted.value = true
        } else if (apiResponse.responseStatus === SecretariatAPI.RESPONSE_STATUSES.AUTHENTICATION_FAILED) {
          secretariatUserStore.$reset()
        } else {
          showErrorToast(UIMessages[apiResponse.responseStatus])
        }
      }
  ).catch((error) => {
    showErrorToast(UIMessages.CONNECTION_ERROR)
  }).finally(() => {
    isLoaderEnabled.value = false
  })
}

function updateSubmissionStatus(submission, newStatus) {
  const apiCall = () => {
    isLoaderEnabled.value = true
    let statusMetadata = null;
    if (newStatus === "REJECTED") statusMetadata = {rejectionReasons: rejectionReasons.value}
    SecretariatAPI.setSubmissionStatus(submission.id, newStatus, secretariatUserStore.data.token, statusMetadata).then(
        (apiResponse) => {
          if (apiResponse.responseStatus === SecretariatAPI.RESPONSE_STATUSES.SUCCESS_WITH_NO_CONTENT) {
            submissions.value.splice(submissions.value.findIndex((element) => element.id === submission.id), 1)
            showSuccessToast("Η αλλαγή κατάστασης έγινε επιτυχώς.")
          } else if (apiResponse.responseStatus === SecretariatAPI.RESPONSE_STATUSES.AUTHENTICATION_FAILED) {
            secretariatUserStore.$reset()
          } else {
            showErrorToast(UIMessages[apiResponse.responseStatus])
          }
        }
    ).catch((error) => {
      showErrorToast(UIMessages.CONNECTION_ERROR)
    }).finally(() => {
      isLoaderEnabled.value = false
    })
  }

  if (newStatus === "COMPLETED") {
    if (!isConfirmationModalDisabled.value) {
      confirmationModalCallback.value = apiCall
      confirmationModal.value.show()
    } else {
      apiCall()
    }
  } else if (newStatus === "REJECTED") {
    rejectionModalCallbackFunction = apiCall
    rejectionReasons.value = ""
    Modal.getOrCreateInstance(rejectionModalDOM.value).show()
  } else {
    apiCall()
  }
}

function resendCompletionEmail(submission) {
  isLoaderEnabled.value = true
  const successMsg = "Το αίτημα σας για επαναποστολή email ολοκλήρωσης λήφθηκε επιτυχώς."

  SecretariatAPI.resendRegistrationCompletedEmail(submission.id, secretariatUserStore.data.token)
      .then((apiResponse) => {
        if (apiResponse.responseStatus === SecretariatAPI.RESPONSE_STATUSES.SUCCESS_WITH_NO_CONTENT) {
          showSuccessToast(successMsg)
        } else if (apiResponse.responseStatus === SecretariatAPI.RESPONSE_STATUSES.AUTHENTICATION_FAILED) {
          secretariatUserStore.$reset()
        } else {
          showErrorToast(UIMessages[apiResponse.responseStatus])
        }
      }).catch((error) => {
    showErrorToast(UIMessages.CONNECTION_ERROR)
  }).finally(() => {
    isLoaderEnabled.value = false
  })
}

function resendRejectionEmail(submission) {
  isLoaderEnabled.value = true
  const successMsg = "Το αίτημα σας για επαναποστολή email απόρριψης λήφθηκε επιτυχώς."

  SecretariatAPI.resendRejectionEmail(submission.id, secretariatUserStore.data.token)
      .then((apiResponse) => {
        if (apiResponse.responseStatus === SecretariatAPI.RESPONSE_STATUSES.SUCCESS_WITH_NO_CONTENT) {
          showSuccessToast(successMsg)
        } else if (apiResponse.responseStatus === SecretariatAPI.RESPONSE_STATUSES.AUTHENTICATION_FAILED) {
          secretariatUserStore.$reset()
        } else {
          showErrorToast(UIMessages[apiResponse.responseStatus])
        }
      }).catch((error) => {
    showErrorToast(UIMessages.CONNECTION_ERROR)
  }).finally(() => {
    isLoaderEnabled.value = false
  })
}

function showRejectionReasons(submission) {
  informationModalBody.value = submission.statusMetadata.rejectionReasons
  informationModal.value.show()
}

onMounted(() => {
  selectedDepartmentId.value =
      route.query.departmentId ? route.query.departmentId : secretariatUserStore.data.details.departments[0].id
  if (selectedDepartmentId.value === route.query.departmentId) { // force download
    getSubmissionsByStatus(currentTab.value)
  }
})

watch(() => route.query, () => {
  getSubmissionsByStatus(route.query.currentTab)
})

watch(selectedDepartmentId, () => {
  router.push({ // Note that the same component instance will be reused
    name: "submissions",
    query: {currentTab: currentTab.value, departmentId: selectedDepartmentId.value}
  })
})

function currentTabChanged(newTab) {
  if (currentTab.value !== newTab) {
    router.push({ // Note that the same component instance will be reused
      name: "submissions",
      query: {currentTab: newTab, departmentId: selectedDepartmentId.value}
    })
  } else { //do a simple refresh
    getSubmissionsByStatus(currentTab.value)
  }
}
</script>

<template>
  <div class="row gx-1 pb-1">
    <div class="col-auto pt-1">Επιλεγμένο Τμήμα:</div>
    <div class="col-auto">
      <select class="form-select" aria-label="Select Department"
              :disabled="secretariatUserStore.data.details.departments.length < 2" v-model="selectedDepartmentId">
        <option v-for="department in secretariatUserStore.data.details.departments" :value="department.id">
          {{ department.title }}
        </option>
      </select>
    </div>
    <div class="col">
      <div class="form-check pt-1 pe-2 d-flex justify-content-center justify-content-sm-end">
        <input class="form-check-input" type="checkbox" v-model="isConfirmationModalDisabled"
               id="disableConfirmationsFlagCheckbox">
        <label class="form-check-label ps-1" for="disableConfirmationsFlagCheckbox"
               style="display: inline-block; text-align: left">
          Απενεργοποίηση Επιβεβαιώσεων Ολοκλήρωσης
        </label>
      </div>
    </div>
    <div class="col-auto pt-2 pt-sm-0">
      <button type="button" class="btn btn-outline-primary" @click="getSubmissionsByStatus(currentTab)"><i
          class="bi-arrow-clockwise d-inline-block"></i> Ανανέωση Αποτελεσμάτων
      </button>
    </div>
  </div>

  <div class="text-center pt-2">
    <table class="table table-bordered align-middle">
      <thead class="sticky-top">
      <tr class="btn-secondary border-0">
        <td colspan="100" class="pb-0 ps-0 border-0">
          <ul class="nav nav-pills" >
            <li class="nav-item" style="cursor: pointer;">
              <span class="nav-link" :class="{active: currentTab === 'UNCHECKED'}"
                    @click="currentTabChanged('UNCHECKED')">Εκκρεμείς</span>
            </li>
            <li class="nav-item" style="cursor: pointer;">
              <a class="nav-link" :class="{active: currentTab === 'CHECKED'}"
                 @click="currentTabChanged('CHECKED')">Ελεγμένες</a>
            </li>
            <li class="nav-item" style="cursor: pointer;">
              <a class="nav-link" :class="{active: currentTab === 'COMPLETED'}" @click="currentTabChanged('COMPLETED')">Ολοκληρωμένες</a>
            </li>
            <li class="nav-item" style="cursor: pointer;">
              <a class="nav-link" :class="{active: currentTab === 'REJECTED'}" @click="currentTabChanged('REJECTED')">Απορριφθείσες</a>
            </li>
          </ul>
        </td>
      </tr>
      <tr class="border-top-0">
        <th scope="col">#</th>
        <th scope="col">ΑΜ</th>
        <th scope="col">Ονοματεπώνυμο</th>
        <th scope="col">Ημερομηνία & Ώρα Υποβολής</th>
        <th scope="col">Φόρμα Επικαιροποίησης στοιχείων</th>
        <th scope="col">Έγγραφο Ταυτοποίησης</th>
        <th scope="col">Φωτογραφία</th>
        <th scope="col">Αποδεικτικό ΑΜΚΑ</th>
        <th scope="col">Υπεύθυνη Δήλωση</th>
        <th scope="col">Αντίγραφο της αίτησης εγγραφής επιτυχόντων</th>
        <th scope="col">Βεβαίωση εγγραφής σε Δημοτολόγιο Νομού Ξάνθης, Ροδόπης ή Έβρου.</th>
        <th scope="col">Πιστοποιητικό διαπίστωσης της πάθησης</th>
        <th scope="col">Τίτλος Απόλυσης</th>
        <th scope="col">Αρχείο zip</th>
        <th scope="col">Συνολικό PDF</th>
        <th scope="col" v-if="!['REJECTED', 'COMPLETED'].includes(currentTab)">Μετακίνηση σε:</th>
        <th scope="col" v-if="currentTab === 'REJECTED'">Λόγοι απόρριψης</th>
        <th scope="col" v-if="currentTab === 'COMPLETED'">Επαναποστολή Email Ολοκλήρωσης</th>
        <th scope="col" v-else-if="currentTab === 'REJECTED'">Επαναποστολή Email Απόρριψης</th>
      </tr>
      </thead>
      <tbody class="table-group-divider" style="overflow:scroll;">
      <template v-if="isLoadingCompleted">
        <tr v-for="(submission, index) in sortedSubmissions"
            :class="{checked: submission.status === 'CHECKED', completed: submission.status === 'COMPLETED', rejected: submission.status === 'REJECTED'}">
          <th scope="row">{{ index + 1 }}</th>
          <td>{{ submission.newStudent.studentNumber }}</td>
          <td>{{ submission.newStudent.firstName }} {{ submission.newStudent.lastName }}</td>
          <td>{{ new Date(submission.timestamp).toLocaleString('EL-GR') }}</td>
          <td>
            <SupportingDocumentDownloadLink :token="secretariatUserStore.data.token"
                                            :submission-id="submission.id"
                                            download-name="Φόρμα Επικαιροποίησης στοιχείων"
                                            doc-type="personalDetailsUpdateForm"
                                            @error="showErrorToast">
              Λήψη
            </SupportingDocumentDownloadLink>
          </td>
          <td>
            <SupportingDocumentDownloadLink :token="secretariatUserStore.data.token"
                                            :submission-id="submission.id"
                                            download-name="Έγγραφο Ταυτοποίησης"
                                            doc-type="identity"
                                            @error="showErrorToast">
              Λήψη
            </SupportingDocumentDownloadLink>
          </td>
          <td>
            <SupportingDocumentDownloadLink :token="secretariatUserStore.data.token"
                                            :submission-id="submission.id"
                                            download-name="Φωτογραφία"
                                            doc-type="photo"
                                            @error="showErrorToast">
              Λήψη
            </SupportingDocumentDownloadLink>
          </td>
          <td>
            <SupportingDocumentDownloadLink :token="secretariatUserStore.data.token"
                                            :submission-id="submission.id"
                                            download-name="Αποδεικτικό ΑΜΚΑ"
                                            doc-type="ssn"
                                            @error="showErrorToast">
              Λήψη
            </SupportingDocumentDownloadLink>
          </td>
          <td>
            <SupportingDocumentDownloadLink :token="secretariatUserStore.data.token"
                                            :submission-id="submission.id"
                                            download-name="Υπεύθυνη Δήλωση"
                                            doc-type="formalDeclaration"
                                            @error="showErrorToast">
              Λήψη
            </SupportingDocumentDownloadLink>
          </td>
          <td>
            <SupportingDocumentDownloadLink :token="secretariatUserStore.data.token"
                                            :submission-id="submission.id"
                                            download-name="Αντίγραφο της αίτησης εγγραφής επιτυχόντων"
                                            doc-type="copyOfMinistryRegistration"
                                            @error="showErrorToast">
              Λήψη
            </SupportingDocumentDownloadLink>
          </td>
          <td>
            <SupportingDocumentDownloadLink
                v-if="SupportingDocumentsDefinitions.get(submission.newStudent.insTypeId).hasOwnProperty('localityCertificate')"
                :token="secretariatUserStore.data.token"
                :submission-id="submission.id"
                download-name="Βεβαίωση εγγραφής σε Δημοτολόγιο Νομού Ξάνθης, Ροδόπης ή Έβρου"
                doc-type="localityCertificate"
                @error="showErrorToast">
              Λήψη
            </SupportingDocumentDownloadLink>
            <span v-else>&#8212;</span>
          </td>
          <td>
            <SupportingDocumentDownloadLink
                v-if="SupportingDocumentsDefinitions.get(submission.newStudent.insTypeId).hasOwnProperty('diseaseCertificate')"
                :token="secretariatUserStore.data.token"
                :submission-id="submission.id"
                download-name="Πιστοποιητικό διαπίστωσης της πάθησης"
                doc-type="diseaseCertificate"
                @error="showErrorToast">
              Λήψη
            </SupportingDocumentDownloadLink>
            <span v-else>&#8212;</span>
          </td>
          <td>
            <SupportingDocumentDownloadLink
                v-if="SupportingDocumentsDefinitions.get(submission.newStudent.insTypeId).hasOwnProperty('schoolLeavingQualification')"
                :token="secretariatUserStore.data.token"
                :submission-id="submission.id"
                download-name="Τίτλος Απόλυσης"
                doc-type="schoolLeavingQualification"
                @error="showErrorToast">
              Λήψη
            </SupportingDocumentDownloadLink>
            <span v-else>&#8212;</span>
          </td>
          <td>
            <SupportingDocumentDownloadLink :token="secretariatUserStore.data.token"
                                            :submission-id="submission.id"
                                            :download-name="submission.newStudent.studentNumber"
                                            doc-type="zip"
                                            @error="showErrorToast">
              Λήψη
            </SupportingDocumentDownloadLink>
          </td>
          <td>
            <SupportingDocumentDownloadLink :token="secretariatUserStore.data.token"
                                            :submission-id="submission.id"
                                            :download-name="submission.newStudent.studentNumber"
                                            doc-type="all-in-one-doc"
                                            @error="showErrorToast">
              Λήψη
            </SupportingDocumentDownloadLink>
          </td>
          <td v-if="!['REJECTED', 'COMPLETED'].includes(currentTab)">
            <div>
              <select class="form-select" aria-label="Change Status"
                      @change.prevent="updateSubmissionStatus(submission, $event.target.value); $event.target.value = 'selectOption'">
                <option selected disabled value="selectOption">---Επιλέξτε---</option>
                <option value="UNCHECKED" v-if="currentTab === 'CHECKED'">Εκκρεμείς</option>
                <option value="CHECKED" v-if="currentTab === 'UNCHECKED'">Ελεγμένες</option>
                <option value="COMPLETED" v-if="['CHECKED', 'UNCHECKED'].includes(currentTab)">Ολοκληρωμένες</option>
                <option value="REJECTED" v-if="currentTab !== 'REJECTED'">Απορριφθείσες</option>
              </select>
            </div>
          </td>
          <td v-if="currentTab === 'REJECTED'">
            <button type="button"
                    class="btn btn-primary rounded rounded-2"
                    @click="showRejectionReasons(submission)"><i class="bi-box-arrow-up-right"
                                                                 style="font-size: 1.4rem"></i>
            </button>
          </td>
          <td v-if="currentTab === 'COMPLETED' || currentTab === 'REJECTED'">
            <button v-if="currentTab === 'COMPLETED'" type="button"
                    class="btn btn-primary rounded rounded-2"
                    @click="resendCompletionEmail(submission)"><i class="bi-repeat" style="font-size: 1.4rem"></i>
            </button>
            <button v-else type="button"
                    class="btn btn-primary rounded rounded-2"
                    @click="resendRejectionEmail(submission)"><i class="bi-repeat" style="font-size: 1.4rem"></i>
            </button>
          </td>
        </tr>
      </template>
      </tbody>
      <tfoot v-if="isLoadingCompleted && submissions.length === 0">
      <tr class="text-center">
        <td colspan="100" style="color: red;" class="bg-light">
          <span>Δεν βρέθηκαν αποτελέσματα <span style="font-size: 1.2rem">&#x1F610.</span> </span>
        </td>
      </tr>
      </tfoot>
    </table>
  </div>

  <div ref="rejectionModalDOM" class="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
       aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header bg-danger-subtle">
          <h1 class="modal-title fs-5">Αιτιολογία Απόρριψης Αίτησης</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          Παρακαλώ περιγράψτε παρακάτω τους λόγους απόρριψης της αίτησης. Οι λόγοι απόρριψης θα κοινοποιηθούν με
          email στον/την φοιτητή/τρια.
          <div class="mb-3">
            <label for="ReasonTextarea" class="form-label"><b>Λόγοι Απόρριψης: </b></label>
            <textarea maxlength="300" v-model="rejectionReasons" class="form-control" id="ReasonTextarea"
                      rows="3"></textarea>
            <p style="text-align: right">{{ 300 - rejectionReasons.length }}/300</p>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-danger" @click="rejectionModalCallbackFunction"
                  data-bs-dismiss="modal" :disabled="rejectionReasons.length === 0">
            Απόρριψη Αίτησης
          </button>
          <button type="button" class="btn btn-primary" data-bs-dismiss="modal">Ακύρωση</button>
        </div>
      </div>
    </div>
  </div>

  <Toast ref="toastDOM">
    <template #title>
      {{ toastTitle }}
    </template>
    <template #message>
      {{ toastMessage }}
    </template>
  </Toast>
  <Loader :is-enabled="isLoaderEnabled"></Loader>
  <ConfirmationModal :on-confirm-callback="confirmationModalCallback" ref="confirmationModal">
    <template #title>Απαιτείται Επιβεβαίωση</template>
    <template #body>
      Με την αλλαγή της κατάστασης της αίτησης σε ολοκληρωμένη θα αποσταλλεί αυτόματα επιβεβαιωτικό
      μήνυμα στον/η φοιτητή/τρια σχετικά με την ολοκλήρωση της εγγραφής του. Η κατάσταση της αίτησης δεν μπορεί κατόπιν
      να αναιρεθεί.
      <br><strong>Είστε σίγουροι ότι θέλετε να συνεχίσετε</strong>;
    </template>
  </ConfirmationModal>
  <InformationModal ref="informationModal">
    <template #title>Λόγοι Απόρριψης</template>
    <template #body>
      {{ informationModalBody }}
    </template>
  </InformationModal>

</template>

<style scoped>

tr.checked:nth-child(2n) > :is(td, th) {
  background-color: rgba(215, 219, 250, 0.5);
}
tr.checked:nth-child(2n+1) > :is(td, th) {
  background-color: #d7dbfa;
}

tr.completed:nth-child(2n) > :is(td, th) {
  background-color: rgba(176, 225, 143, 0.5);
}
tr.completed:nth-child(2n+1) > :is(td, th) {
  background-color: #b0e18f;
}

tr.rejected:nth-child(2n) > :is(td, th) {
  background-color: rgba(255, 230, 225, 0.5);
}
tr.rejected:nth-child(2n+1) > :is(td, th) {
  background-color: #ffe6e1;
}
</style>