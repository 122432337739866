export class LocalStorageWrapper {
    static getItem(key) {
        return window.localStorage.getItem(key)
    }

    static watch(key, callback) {
        let listener = (event) => {
            if (event.key === key) {
                callback(event.newValue)
            }
        }

        window.addEventListener('storage', listener)

        return () => window.removeEventListener('storage', listener)
    }

    static setItem(key, value) {
        window.localStorage.setItem(key, value)
    }
}