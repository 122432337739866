<script setup>
import {ref} from "vue"
import {BaseAPI, NewStudentAPI, SecretariatAPI} from "@/util/api"
import {UIMessages} from "@/util/ui-messages"

const props = defineProps({
  submissionId: {
    type: String,
    required: true
  },
  docType: {
    type: String,
    required: true
  },
  token: {
    type: String,
    required: true
  },
  downloadName: {
    type: String,
    isRequired: false,
  }
})
const emit = defineEmits(['error'])
const hiddenDownloadLink = ref(null)

function download() {
  BaseAPI.getSubmittedDocument(props.submissionId, props.token, props.docType).then(
      (apiResponse) => {
        if (apiResponse.responseStatus === NewStudentAPI.RESPONSE_STATUSES.SUCCESS) {
          const mimeTypeToSuffix = new Map([
            ["application/pdf", "pdf"],
            ["image/jpeg", "jpeg"],
            ["image/png", "png"]
          ])
          let filename;
          if (props.docType === "zip" || props.docType === "all-in-one-doc") {
            filename = props.downloadName ? props.downloadName: "all"
          } else {
            filename = props.downloadName ? props.downloadName : props.docType
          }// TODO props.docType null/undefined?

          let blob = apiResponse.body
          let suffix = props.docType !== "zip" ? mimeTypeToSuffix.get(blob.type) : "zip"
          hiddenDownloadLink.value.href = window.URL.createObjectURL(blob);
          hiddenDownloadLink.value.download = `${filename}.${suffix}`
          hiddenDownloadLink.value.target="_blank"
          hiddenDownloadLink.value.click()
          // TODO add prop: openInTabFlag
          // window.open(hiddenDownloadLink.value.href)
        } else {
          emit("error", UIMessages[apiResponse.responseStatus])
        }
      }).catch((error) => {
    emit("error", UIMessages.CONNECTION_ERROR)
  })
}

</script>

<script>

</script>

<template>
  <span class="downloadLink" @click='download'><slot></slot> </span>
  <a ref="hiddenDownloadLink" style="display: none"></a>
</template>

<style scoped>
.downloadLink {
  text-decoration: underline;
  cursor: pointer;
  color: rgb(13, 110, 253);
  font-weight: 600;
}
</style>