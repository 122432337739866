<script setup>
import {nextTick, onBeforeUnmount, onMounted, ref} from "vue"
import {Modal} from "bootstrap"

const props = defineProps({
      onCloseCallback: {
        type: Function,
        default: () => {
        }
      }
    }
)

const informationModalDom = ref(null)

onMounted(() => {
  Modal.getOrCreateInstance(informationModalDom.value) // create instance
  informationModalDom.value.addEventListener("hide.bs.modal", props.onCloseCallback)
})

function show() {
  nextTick(() => {
    Modal.getInstance(informationModalDom.value).show()
  })
}

function hide() {
  Modal.getInstance(informationModalDom.value).hide()
}

onBeforeUnmount(() => {
  informationModalDom.value.removeEventListener("hide.bs.modal", props.onCloseCallback)
  Modal.getInstance(informationModalDom.value).hide();
  Modal.getInstance(informationModalDom.value).dispose();
})

defineExpose({
  show,
  hide
})


</script>

<template>
  <div class="modal" data-bs-keyboard="false" tabindex="-1" ref="informationModalDom">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            <i class="bi-exclamation-diamond" style="font-size: 1.5rem"></i>&nbsp;
            <span>
            <slot name="title"></slot>
            </span>
          </h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <slot name="body"></slot>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-dark" data-bs-dismiss="modal">Κλείσιμο</button>
        </div>
      </div>
    </div>
  </div>


</template>

<style scoped>

</style>