<script>
export const ErrorTypes = {
  NOT_ACCEPTED_FILE_TYPE: 1,
  FILE_SIZE_LIMIT_EXCEEDED: 2,
}
</script>

<script setup>

import {ref} from "vue";
import Loader from "@/components/Loader.vue";

const emit = defineEmits(['fileChange', 'inputError'])
const props = defineProps({
  mimeTypes: {
    type: Array,
    required: false
  },
  sizeLimitInBytes: {
    type: Number,
    required: false
  }
})


const fileInputDOM = ref(null)
const fileInputPath = ref(null)
const isLoaderEnabled = ref(false)

function selectFile() {
  isLoaderEnabled.value = true
  fileInputDOM.value.click()
  isLoaderEnabled.value = false
}

function fileChangeEvent(ev) {
  let hasAcceptedFileType = !props.mimeTypes || props.mimeTypes.includes(fileInputDOM.value.files[0].type)
  if (hasAcceptedFileType) {
    if (props.sizeLimitInBytes && fileInputDOM.value.files[0].size > props.sizeLimitInBytes) {
      fileInputPath.value = ''
      fileInputDOM.value.value = ''
      emit('inputError', {errorType: ErrorTypes.FILE_SIZE_LIMIT_EXCEEDED})
    } else {
      fileInputPath.value = fileInputDOM.value.value
      emit('fileChange', {file: fileInputDOM.value.files[0]})
    }
  } else {
    fileInputPath.value = ''
    fileInputDOM.value.value = ''
    emit('inputError', {errorType: ErrorTypes.NOT_ACCEPTED_FILE_TYPE})
  }
}

defineExpose({
  ErrorTypes
})
</script>

<template>
  <div class="input-group">
    <button class="btn btn-outline-secondary" type="button" @click="selectFile">Επιλογή Αρχείου</button>
    <input type="text" class="form-control" placeholder="Δεν έχει επιλεγεί αρχείο" @click="selectFile"
           tabindex="-1" v-model="fileInputPath" readonly>
    <input type="file" class="d-none" ref="fileInputDOM" @change="fileChangeEvent" :accept="mimeTypes">
  </div>
  <Loader :is-enabled="isLoaderEnabled"></Loader>
</template>

<style scoped>
input[type="text"]:read-only {
  border-color: var(--bs-border-color);
  margin-left: unset !important;
  box-shadow: unset;
  cursor: pointer;
}


</style>