import {BaseAPI} from "@/util/api";

export const UIMessages = Object.freeze({
    BAD_CREDENTIALS: "Τα στοιχεία που δώσατε δεν είναι έγκυρα.",
    INVALID_OTP: "Ο κωδικός που εισαγάγατε δεν είναι έγκυρος",
    BAD_CREDENTIALS_NEW_STUDENT: `Τα στοιχεία που εισάγατε δεν είναι έγκυρα. Σε περίπτωση που απέτυχε επανειλημμένα η είσοδος 
    σας στο σύστημα παρακαλούμε επικοινωνήστε με τη γραμματεία του τμήματος σας στα στοιχεία επικοινωνίας που θα βρείτε 
    στον   <a href="https://sis-apps.unipi.gr/prereg/ui/new_student_manual.pdf" class="text-center link-danger" target="_blank" download="Οδηγός χρήσης εφαρμογής προεγγραφής φοιτητών.pdf">
    <strong>οδηγό χρήσης της εφαρμογής</strong></a>.`,
    [BaseAPI.RESPONSE_STATUSES.AUTHENTICATION_FAILED]: "Αποτυχία Αυθεντικοποίησης",
    [BaseAPI.RESPONSE_STATUSES.UNEXPECTED_ERROR]: "Απροσδιόριστο Σφάλμα. Παρακαλώ προσπαθήστε ξανά αργότερα.",
    [BaseAPI.RESPONSE_STATUSES.TOO_MANY_REQUESTS]: "Απόρριψη αίτησης. Πάρα πολλά αιτήματα. Παρακαλώ προσπαθήστε ξανά αργότερα.",
    [BaseAPI.RESPONSE_STATUSES.SERVER_ERROR]: "Σφάλμα Εξυπηρετητή. Παρακαλώ προσπαθήστε ξανά αργότερα.",
    [BaseAPI.RESPONSE_STATUSES.LOCKED_RESOURCE]: "Η εφαρμογή είναι κλειδωμένη. Δεν δέχεται νέα αιτήματα.",
    CONNECTION_ERROR: "Σφάλμα σύνδεσης. Παρακαλώ προσπαθήστε ξανά αργότερα."
})