<script>
export const TOAST_TYPES = {
  SUCCESS_TOAST: "SUCCESS_TOAST",
  ERROR_TOAST: "ERROR_TOAST",
  INFO_TOAST: "INFO_TOAST"
}
</script>

<script setup>
import {Toast} from 'bootstrap';
import {nextTick, onBeforeUnmount, ref} from 'vue';


const props = defineProps({
  toastType: {
    default: TOAST_TYPES.ERROR_TOAST
  }
})

const toastDom = ref(null)
const messageDOM = ref(null)

onBeforeUnmount(() => {
  Toast.getOrCreateInstance(toastDom.value).hide()
  Toast.getOrCreateInstance(toastDom.value).dispose()
})

function show() {
  nextTick(_show)
}

function _show() {
  // Delay is calculated assuming a reading rate of 200 wpm (words per minutes)
  let delay = Math.ceil(messageDOM.value.innerText.trim().split(/\s+/).length / 200 * 60 * 1000)
  Toast.getOrCreateInstance(toastDom.value, {delay: delay}).show()
}

function hide() {
  Toast.getOrCreateInstance(toastDom.value).hide()
  Toast.getOrCreateInstance(toastDom.value).dispose()
}

defineExpose({
  show,
  hide
})
</script>

<template>
  <div class="toast-container position-fixed top-0 end-0">
    <div :class="{
      'text-danger': props.toastType === TOAST_TYPES.ERROR_TOAST, 'border-danger': props.toastType === TOAST_TYPES.ERROR_TOAST,
            'text-success': props.toastType === TOAST_TYPES.SUCCESS_TOAST, 'border-success': props.toastType === TOAST_TYPES.SUCCESS_TOAST,
            'text-info': props.toastType === TOAST_TYPES.INFO_TOAST, 'border-info': props.toastType === TOAST_TYPES.INFO_TOAST,
    }"
         class="toast mb-1 me-1" role="alert" aria-live="assertive"
         aria-atomic="true" ref="toastDom">
      <div class="toast-header" :class="{
        'text-danger': props.toastType === TOAST_TYPES.ERROR_TOAST,
        'text-success': props.toastType === TOAST_TYPES.SUCCESS_TOAST,
        'text-info': props.toastType === TOAST_TYPES.INFO_TOAST,
    }">
        <i :class="{
        'bi-exclamation-triangle': props.toastType === TOAST_TYPES.ERROR_TOAST,
        'bi-check': props.toastType === TOAST_TYPES.SUCCESS_TOAST,
        'bi-info-lg': props.toastType === TOAST_TYPES.INFO_TOAST,
    }"></i>
        <strong class="ps-1 me-auto">
          <slot name="title"></slot>
        </strong>
        <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
      </div>
      <div class="toast-body" style="text-align: justify" ref="messageDOM">
        <slot name="message"></slot>
      </div>
    </div>
  </div>
</template>

<style scoped>
.toast {
  background-color: white;
}
</style>