export class SupportingDocumentsDefinitions {
    static get(newStudentInsTypeId) {
        let definitions = {
            personalDetailsUpdateForm: {
                title: 'Φόρμα επικαιροποίησης στοιχείων',
                isRequired: true,
                sizeLimitInBytes: 3145728,
                acceptedFileTypes: ['application/pdf', 'image/png', 'image/jpeg']
            },
            identity: {
                title: 'Έγγραφο Ταυτοποίησης (Αστ. Ταυτότητα ή Διαβατήριο)',
                isRequired: true,
                sizeLimitInBytes: 3145728,
                acceptedFileTypes: ['application/pdf', 'image/png', 'image/jpeg']
            },
            photo: {
                title: 'Φωτογραφία',
                isRequired: true,
                sizeLimitInBytes: 3145728,
                acceptedFileTypes: ['application/pdf', 'image/png', 'image/jpeg']
            },
            formalDeclaration: {
                title: 'Υπεύθυνη δήλωση',
                isRequired: true,
                sizeLimitInBytes: 3145728,
                acceptedFileTypes: ['application/pdf', 'image/png', 'image/jpeg']
            },
            ssn: {
                title: 'Αριθμός Μητρώου Κοινωνικής Ασφάλισης (ΑΜΚΑ)',
                isRequired: true,
                sizeLimitInBytes: 3145728,
                acceptedFileTypes: ['application/pdf', 'image/png', 'image/jpeg']
            },
            copyOfMinistryRegistration: {
                title: 'Αντίγραφο της αίτησης εγγραφής επιτυχόντων',
                isRequired: true,
                sizeLimitInBytes: 3145728,
                acceptedFileTypes: ['application/pdf', 'image/png', 'image/jpeg']
            }
        }

        if (['90BE652A-5751-4FE0-9730-0D42ECA400D5', '3147EBE9-2A25-400E-A547-71F6BFBE24B7',
            '88CDE8A0-C97A-47B2-ABF7-A535D71C7248'].includes(newStudentInsTypeId)) {
            definitions['localityCertificate'] = {
                title: 'Βεβαίωση εγγραφής σε Δημοτολόγιο Νομού Ξάνθης, Ροδόπης ή Έβρου',
                isRequired: true,
                sizeLimitInBytes: 3145728,
                acceptedFileTypes: ['application/pdf', 'image/png', 'image/jpeg'],
            }
        }

        if ('8C3CE220-EF20-476A-B74F-A5837ED5B727' === newStudentInsTypeId) {
            definitions['diseaseCertificate'] = {
                title: 'Πιστοποιητικό διαπίστωσης της πάθησης',
                isRequired: true,
                sizeLimitInBytes: 3145728,
                acceptedFileTypes: ['application/pdf', 'image/png', 'image/jpeg'],
            }
        }

        if ('8C3CE220-EF20-476A-B74F-A5837ED5B727' === newStudentInsTypeId) {
            definitions['schoolLeavingQualification'] = {
                title: 'Τίτλος Απόλυσης',
                isRequired: true,
                sizeLimitInBytes: 3145728,
                acceptedFileTypes: ['application/pdf', 'image/png', 'image/jpeg'],

            }
        }

        return definitions;
    }
}