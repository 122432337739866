<script setup>
import FileInput from "@/components/FileInput.vue"
import {ErrorTypes} from "@/components/FileInput.vue"
import {computed, onBeforeUnmount, onMounted, ref, watch} from "vue"
import {NewStudentAPI} from "@/util/api"
import {useNewStudentUserStore} from "@/stores/new-student-user"
import Toast from "@/components/Toast.vue"
import {UIMessages} from "@/util/ui-messages"
import Loader from "@/components/Loader.vue"
import ConfirmationModal from "@/components/ConfirmationModal.vue"
import {SupportingDocumentsDefinitions} from "@/util/supporting-documents-definitions"
import {useRoute, useRouter} from "vue-router"
import SupportingDocumentDownloadLink from "@/components/SupportingDocumentDownloadLink.vue"
import InformationModal from "@/components/InformationModal.vue";


const FORMAL_DECLARATION_PDF_URL = import.meta.env.BASE_URL + "/formal_declaration.pdf"
const FORMAL_DECLARATION_DOC_URL = import.meta.env.BASE_URL + "/formal_declaration.doc"
const router = useRouter();
const route = useRoute()
const newStudentUserStore = useNewStudentUserStore()
const filesFields =
    computed(() => {
          let supportingDocumentDefs = SupportingDocumentsDefinitions.get(newStudentUserStore.data.details.insTypeId)
          for (const key in supportingDocumentDefs) {
            supportingDocumentDefs[key]["selectedFile"] = null
          }
          return supportingDocumentDefs
        }
    )

const newSubmission = ref({})
const isLoaderEnabled = ref(false)
const toastDOM = ref(null)
const toastTitle = ref('')
const toastMessage = ref('');
const stepCounter = ref(1)
const hiddenPersonalDetailsUpdateFormTemplateDownloadLink = ref(null)
const submissionLockModalDOM = ref(null)
const submissionLockModalTitle = ref("")
const submissionLockReason = ref("") // Note: Don't allow user defined data here.
let newSubmissionWatchStopHandle;

const confirmationModal = ref(null)
const confirmationModalCallback = ref(() => {
  isLoaderEnabled.value = true
  NewStudentAPI.uploadSupportingDocuments(newStudentUserStore.data.token, filesFields.value.personalDetailsUpdateForm.selectedFile,
      filesFields.value.identity.selectedFile, filesFields.value.photo.selectedFile,
      filesFields.value.formalDeclaration.selectedFile, filesFields.value.ssn.selectedFile,
      filesFields.value.copyOfMinistryRegistration.selectedFile,
      "localityCertificate" in filesFields.value ? filesFields.value.localityCertificate.selectedFile : null,
      "diseaseCertificate" in filesFields.value ? filesFields.value.diseaseCertificate.selectedFile : null,
      "schoolLeavingQualification" in filesFields.value ? filesFields.value.schoolLeavingQualification.selectedFile : null
  ).then(
      (apiResponse) => {
        if (apiResponse.responseStatus === NewStudentAPI.RESPONSE_STATUSES.SUCCESS) {
          newSubmission.value = apiResponse.body
          newStudentUserStore.data.details.submissions.push(newSubmission.value)
          stepCounter.value += 1
        } else if (apiResponse.responseStatus === NewStudentAPI.RESPONSE_STATUSES.AUTHENTICATION_FAILED) {
          newStudentUserStore.$reset()
        } else if (apiResponse.responseStatus === NewStudentAPI.RESPONSE_STATUSES.BAD_REQUEST) {
          showErrorToast("Εντοπίστηκαν μη αποδεκτοί τύποι αρχείων.")
        } else if (apiResponse.responseStatus === NewStudentAPI.RESPONSE_STATUSES.CONFLICT) {
          submissionLockModalTitle.value = "Εντοπίστηκε Νέα Αίτηση"
          submissionLockReason.value = 'Εντοπίστηκε νέα αίτηση και ως εκ τούτου δεν μπορείτε να υποβάλετε άλλη. '
          submissionLockModalDOM.value.show()
        } else {
          showErrorToast(UIMessages[apiResponse.responseStatus])
        }
      }
  ).catch((error) => {
    showErrorToast(UIMessages.CONNECTION_ERROR)
  }).finally(() => {
    isLoaderEnabled.value = false
  })
})

function showErrorToast(message) {
  toastTitle.value = 'Αποτυχία'
  toastMessage.value = message
  toastDOM.value.show()
}

function fileInputErrorEvent(info) {
  if (info.errorType === ErrorTypes.FILE_SIZE_LIMIT_EXCEEDED) {
    showErrorToast('Το μέγεθος του αρχείου δεν είναι αποδεκτό.')
  } else {
    showErrorToast('Ο τύπος του αρχείου που επιλέξατε δεν είναι αποδεκτός.')
  }
}

function submit() {
  for (let fileFieldData of Object.values(filesFields.value)) {
    if (fileFieldData.isRequired && fileFieldData.selectedFile == null) {
      showErrorToast('Δεν έχουν εισαχθεί όλα τα απαιτούμενα αρχεία.')
      return
    }
  }
  confirmationModal.value.show()
}

function downloadPersonalDetailsUpdateFormTemplate() {
  NewStudentAPI.getPersonalDetailsUpdateFormTemplate(newStudentUserStore.data.token).then(
      (apiResponse) => {
        if (apiResponse.responseStatus === NewStudentAPI.RESPONSE_STATUSES.SUCCESS) {
          let blob = apiResponse.body
          hiddenPersonalDetailsUpdateFormTemplateDownloadLink.value.href = window.URL.createObjectURL(blob);
          hiddenPersonalDetailsUpdateFormTemplateDownloadLink.value.download = 'Φόρμα Επικαιροποίησης Στοιχείων.pdf'
          hiddenPersonalDetailsUpdateFormTemplateDownloadLink.value.click()
        } else if (apiResponse.responseStatus === NewStudentAPI.RESPONSE_STATUSES.AUTHENTICATION_FAILED) {
          newStudentUserStore.$reset()
        } else {
          showErrorToast("Αδυναμία λήψης αρχείου.")
        }
      }).catch((error) => {
    showErrorToast(UIMessages.CONNECTION_ERROR)
  })
}

onMounted(() => {
  newSubmissionWatchStopHandle = watch(() => newStudentUserStore.data.details.submissions, () => {
    if (stepCounter.value === 3) return
    for (let submission of newStudentUserStore.data.details.submissions) {
      if (submission.status !== "REJECTED") {
        submissionLockModalTitle.value =
            submission.status === "COMPLETED" ? "Εντοπίστηκε Ολοκληρωμένη Αίτηση" : "Αίτηση σε εκκρεμότητα"

        let temp = submission.status === "COMPLETED" ? "ολοκληρωμένη αίτηση" : "ενεργή αίτηση"
        submissionLockReason.value = `Δεν μπορείτε να υποβάλετε νέα αίτηση καθώς υπάρχει ήδη μια ${temp}.`

        submissionLockModalDOM.value.show()
      }
    }
  }, {immediate: true})
})

onBeforeUnmount(() => {
  newSubmissionWatchStopHandle()
})

</script>

<template>
  <div class="container pt-1">
    <div class="card row col-12 mb-2" v-if="stepCounter === 1">
      <div class="card-header d-flex justify-content-between text-md-start text-center">
        <h6>Προετοιμασία Απαιτούμενων Δικαιολογητικών</h6>
        <button type="button" class="btn-close" aria-label="Close" @click="router.push('/menu')"></button>
      </div>
      <div class="card-body">
        <p class="card-text">Για την οριστικοποίηση της εγγραφής σας απαιτείται να υποβάλετε τα παρακάτω
          δικαιολογητικά:</p>
        <ol>
          <li><b>Φόρμα Επικαιροποίησης Στοιχείων συμπληρωμένη και υπογεγραμμένη.</b><br/> Για να κάνετε λήψη της Φόρμας
            Επικαιροποίησης Στοιχείων επιλέξτε
            <span style="text-decoration: underline; cursor: pointer; color: rgb(13, 110, 253)"
                  @click="downloadPersonalDetailsUpdateFormTemplate"><strong>εδώ</strong></span>.
          </li>
          <li><b>Έγγραφο Ταυτοποίησης</b> (Φωτοαντίγραφο αστυνομικής ταυτότητας όπου θα φαίνονται σε μια σελίδα και οι 2
            όψεις ή διαβατήριο).
          </li>
          <li><b>Φωτογραφία</b> (πρόσφατη, έγχρωμη ή ασπρόμαυρη, σε ουδέτερο φόντο που να απεικονίζει ευκρινώς μόνο το
            πρόσωπο του φοιτητή).
          </li>
          <li><b>Υπεύθυνη δήλωση εκδοθείσα μέσω gov.gr ή με επικύρωση του γνήσιου της υπογραφής μέσω Κέντρου
            Εξυπηρέτησης Πολιτών (ΚΕΠ).</b><br/>
            Για να κάνετε λήψη του προτύπου της υπεύθυνης δήλωσης σε μορφή pdf επιλέξτε
            <a :href="FORMAL_DECLARATION_PDF_URL"
               download="ΥΠΟΔΕΙΓΜΑ - Υπεύθυνη Δήλωση 2023-24.pdf"><strong>εδώ</strong></a>
            (ή, εναλλακτικά, σε μορφή doc επιλέξτε
            <a :href="FORMAL_DECLARATION_DOC_URL"
               download="ΥΠΟΔΕΙΓΜΑ - Υπεύθυνη Δήλωση 2023-24.doc"><strong>εδώ</strong></a>).
          </li>
          <li><b> Αριθμός Μητρώου Κοινωνικής Ασφάλισης (ΑΜΚΑ) </b> (απλή εκτύπωση από την ηλεκτρονική ιστοσελίδα
            <a href="https://www.amka.gr/AMKAGR" target="_blank">https://www.amka.gr/AMKAGR</a>).
          </li>
          <li><b>Αίτηση εγγραφής επιτυχόντων</b> (σε μορφή pdf, όπως εκτυπώνεται από το σύστημα <a
              href="https://eregister.it.minedu.gov.gr/" target="_blank">https://eregister.it.minedu.gov.gr)</a>.
          </li>
          <li v-if="'localityCertificate' in filesFields"><b>Βεβαίωση εγγραφής σε Δημοτολόγιο Νομού Ξάνθης,
            Ροδόπης ή Έβρου.</b></li>
          <li v-if="'diseaseCertificate' in filesFields"><b>Πιστοποιητικό διαπίστωσης της πάθησης, σύμφωνα με τις
            οδηγίες του ΥΠΑΙΘΑ. </b></li>
          <li v-if="'schoolLeavingQualification' in filesFields"><b>Τίτλος Απόλυσης (αντίγραφο απολυτηρίου ή
            πτυχίου ή
            αποδεικτικού του σχολείου από όπου αποφοιτήσατε).</b></li>
        </ol>
        <div class="btn-group" role="group">
          <button type="button" class="btn btn-primary" @click="router.push('/menu')">Πίσω</button>
          <button class="btn btn-success" @click="stepCounter++">Συνέχεια</button>
        </div>
      </div>
    </div>

    <div class="card row col-12" v-show="stepCounter === 2">
      <div class="card-header d-flex justify-content-between text-md-start text-center">
        <h6>Επισύναψη Απαιτούμενων Δικαιολογητικών</h6>
        <button type="button" class="btn-close" aria-label="Close" @click="router.push('/menu')"></button>
      </div>
      <div class="card-body">
        <div class="alert alert-light d-flex align-items-center" role="alert">
          <div>
            Οι αποδεκτοί τύποι αρχείων είναι pdf, png και jpeg.<br/>
            Το μέγεθος κάθε αρχείου δεν πρέπει να υπερβαίνει τα 3MB.
          </div>
        </div>
        <template v-for="(fileField, fieldId) in filesFields">
          <div class="row col-auto align-items-center pb-3 pb-md-2">
            <div class="col-md-6 text-md-end col-12 text-center" style="line-height: normal;">
              {{ fileField.title }}:
            </div>
            <div class="col">
              <FileInput :mime-types="filesFields[fieldId].acceptedFileTypes"
                         :size-limit-in-bytes="fileField.sizeLimitInBytes"
                         @fileChange="(selectedFile) => {toastDOM.hide(); filesFields[fieldId].selectedFile = selectedFile.file}"
                         @inputError="fileInputErrorEvent"></FileInput>
            </div>
          </div>
        </template>
        <div class="btn-group" role="group">
          <button type="button" class="btn btn-primary" @click="stepCounter--">Πίσω</button>
          <button type="button" class="btn btn-success" @click="submit">Υποβολή</button>
        </div>
      </div>
    </div>

    <div class="card row col-12" v-if="stepCounter === 3">
      <div class="card-header d-flex justify-content-between text-md-start text-center">
        <h6>Επιτυχής Υποβολή</h6>
        <button type="button" class="btn-close" aria-label="Close" @click="router.push('/menu')"></button>
      </div>
      <div class="card-body">
        Η αίτηση σας υποβλήθηκε στις
        <strong>{{
            new Date(newSubmission.timestamp).toLocaleString("EL-GR")
          }} </strong>
        με κωδικό αίτησης <strong>{{ newSubmission.id.replaceAll("-", "") }}</strong>.
        <div>
          Τα αρχεία που υποβλήθηκαν είναι τα ακόλουθα:
          <ol class="align-items-center pb-2">
            <template v-for="(fileField, fieldId) in filesFields">
              <li>
                <SupportingDocumentDownloadLink :token="newStudentUserStore.data.token"
                                                :submission-id="newSubmission.id"
                                                :download-name="filesFields[fieldId].title"
                                                :doc-type="fieldId"
                                                @error="showErrorToast">{{ fileField.title }}
                </SupportingDocumentDownloadLink>
              </li>
            </template>
          </ol>
        </div>
        <div class="btn-group" role="group" aria-label="Button group">
          <button class="btn btn-primary" @click="router.push('/menu')">Επιστροφή στο Κεντρικό Μενού</button>
          <button class="btn btn-outline-success" @click="router.push('/logout')">Αποσύνδεση</button>
        </div>
      </div>
    </div>
  </div>
  <Loader :is-enabled="isLoaderEnabled"></Loader>
  <Toast ref="toastDOM">
    <template #title>
      {{ toastTitle }}
    </template>
    <template #message>
      {{ toastMessage }}
    </template>
  </Toast>
  <ConfirmationModal :on-confirm-callback="confirmationModalCallback" ref="confirmationModal">
    <template #title>Απαιτείται Επιβεβαίωση</template>
    <template #body>Είστε σίγουροι ότι θέλετε να προχωρήσετε στην υποβολή των δικαιολογητικών;
    </template>
  </ConfirmationModal>

  <InformationModal ref="submissionLockModalDOM"
                    :on-close-callback="()=>{if(route.name === 'NewSubmission') router.push('/menu')}"> <!-- Go to menu if no link has been clicked inside the modal -->
    <template #title>{{ submissionLockModalTitle }}</template>
    <template #body>
      <div style="text-align: justify">
        {{ submissionLockReason }}
        Ανατρέξτε στο <router-link to="/submissions-history">Ιστορικό Αιτήσεων</router-link> για περισσότερες πληροφορίες.
      </div>
    </template>
  </InformationModal>

  <a ref="hiddenPersonalDetailsUpdateFormTemplateDownloadLink" style="display: none"></a>
</template>

<style scoped>

</style>