<script setup>
import {useNewStudentUserStore} from "@/stores/new-student-user"
import {computed, ref} from "vue"
import SupportingDocumentDownloadLink from "@/components/SupportingDocumentDownloadLink.vue"
import {SupportingDocumentsDefinitions} from "@/util/supporting-documents-definitions"
import {useRouter} from "vue-router"
import Toast from "@/components/Toast.vue"

const newStudentUserStore = useNewStudentUserStore()
const router = useRouter();

const supportingDocumentDefs =
    computed(() => SupportingDocumentsDefinitions.get(newStudentUserStore.data.details.insTypeId))

const statusesEL = {
  UNCHECKED: "ΠΡΟΣ ΕΛΕΓΧΟ",
  CHECKED: "ΕΛΕΓΜΕΝΗ",
  COMPLETED: "ΟΛΟΚΛΗΡΩΜΕΝΗ",
  REJECTED: "ΑΠΟΡΡΙΦΘΕΙΣΑ"
}

const toastDOM = ref(null)
const toastTitle = ref('')
const toastMessage = ref('')

const sortedSubmissions = computed(() => newStudentUserStore.data.details.submissions.toSorted(
    function (a, b) {
      return -((new Date(a.timestamp)).getTime() - (new Date(b.timestamp)).getTime())
    }
))

function showErrorToast(message) {
  toastTitle.value = 'Αποτυχία'
  toastMessage.value = message
  toastDOM.value.show()
}
</script>

<template>
  <div class="container pt-1">
    <div class="card row col-12 mb-2">
      <div class="card-header d-flex justify-content-between text-md-start text-center">
        <h6>Ιστορικό Αιτήσεων</h6>
        <button type="button" class="btn-close" aria-label="Close" @click="router.push('/menu')"></button>
      </div>
      <div class="card-body">
        <div v-if="sortedSubmissions.length === 0" style="text-align: center; font-weight: 500">Δεν βρέθηκαν αιτήσεις
        </div>
        <div class="accordion" id="accordion-history">
          <div class="accordion-item"
               v-for="(submission, index) in sortedSubmissions"
               :class="submission.status">
            <h2 class="accordion-header">
              <button class="accordion-button" type="button"
                      data-bs-toggle="collapse"
                      :data-bs-target="'#accordion-item-'+ index"
                      aria-expanded="true"
                      :aria-controls="'accordion-item-'+ index">
                Αίτηση #{{ sortedSubmissions.length - index }}
                &nbsp;
                <span class="submission-status">
                [{{
                    statusesEL[submission.status]
                  }}]
                  </span>
              </button>
            </h2>
            <div :id="'accordion-item-'+ index" class="accordion-collapse collapse show">
              <div class="accordion-body">
                Η αίτηση σας υποβλήθηκε στις
                <strong>{{ new Date(submission.timestamp).toLocaleString("EL-GR") }} </strong>
                με κωδικό αίτησης <strong> {{ submission.id.replaceAll("-", "") }} </strong>.
                <div>
                  Τα αρχεία που υποβλήθηκαν είναι τα ακόλουθα:
                  <ol class="align-items-center pb-2">
                    <template v-for="(doc, docId) in supportingDocumentDefs">
                      <li>
                        <SupportingDocumentDownloadLink :token="newStudentUserStore.data.token"
                                                        :submission-id="submission.id"
                                                        :download-name="supportingDocumentDefs[docId].title"
                                                        :doc-type="docId"
                                                        @error="showErrorToast">{{ doc.title }}
                        </SupportingDocumentDownloadLink>
                      </li>
                    </template>
                  </ol>
                  <div v-if="submission.status === 'REJECTED'">
                    <span style="font-weight: bold; text-decoration: underline">Η αίτηση σας απορρίφθηκε για τους παρακάτω λόγους:</span>
                    <p> {{ submission.statusMetadata ? submission.statusMetadata.rejectionReasons : "-" }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <button class="btn btn-primary mt-3 mb-3" @click="router.push('/menu')">Επιστροφή στο Κεντρικό Μενού</button>

        <div class="alert alert-warning" role="alert">
          <h5><i class="bi-info-square-fill" style="font-size: 1.5rem"></i> Επεξήγηση Καταστάσεων</h5>
          <hr>
          <ol>
            <li><strong>ΠΡΟΣ ΕΛΕΓΧΟ:</strong> Η αίτηση σας έχει υποβληθεί επιτυχώς και αναμένεται ο έλεγχος των
              υποβληθέντων δικαιολογητικών απο τη γραμματεία.
            </li>
            <li><strong>ΕΛΕΓΜΕΝΗ:</strong> Ο έλεγχος των πιστοποιητικών ολοκληρώθηκε επιτυχώς και αναμένεται η
              ολοκλήρωση της εγγραφής σας.
            </li>
            <li><strong>ΟΛΟΚΛΗΡΩΜΕΝΗ:</strong> Η εγγραφή σας έχει ολοκληρωθεί επιτυχώς και σας έχει αποσταλεί
              σχετικό email από τη γραμματεία.
            </li>
            <li><strong>ΑΠΟΡΡΙΦΘΕΙΣΑ:</strong> Η αίτηση σας απορρίφθηκε από τη γραμματεία. Μπορείτε να δείτε
              τους λόγους της απόρριψης μέσα από το ιστορικό των αιτήσεων. Επίσης, μπορείτε να υποβάλετε εκ νέου
              αίτηση.
            </li>
          </ol>
        </div>
      </div>
    </div>
  </div>
  <Toast ref="toastDOM">
    <template #title>
      {{ toastTitle }}
    </template>
    <template #message>
      {{ toastMessage }}
    </template>
  </Toast>
</template>

<style scoped>

.submission-status {
  font-weight: bold;
}

.UNCHECKED > .accordion-header > button > .submission-status {
  color: dimgray;
}

.CHECKED > .accordion-header > button > .submission-status {
  color: blue;
}

.COMPLETED > .accordion-header > button > .submission-status {
  color: green;
}

.REJECTED > .accordion-header > button > .submission-status {
  color: darkred;
}

</style>