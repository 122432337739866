import {computed, ref, watch} from 'vue'
import {defineStore} from 'pinia'
import {LocalStorageWrapper} from "@/util/local-storage-wrapper";

class SecretariatUser {
    toJSON() {
        return {token: this.token, details: this.details}
    }

    token = null
    details = {
        id: null,
        username: null,
        departments: null
    }
}

export const useSecretariatUserStore =
    defineStore('secretariatUserStore', () => {
        const data = ref(new SecretariatUser())
        let temp = JSON.parse(LocalStorageWrapper.getItem("secretariatUser"))
        if (temp != null) {
            data.value = temp
        }
        const isLoggedIn = computed(() => data.value.token != null)

        watch(data, (newData) => {
            if (JSON.stringify(newData) !== LocalStorageWrapper.getItem('secretariatUser')) {
                LocalStorageWrapper.setItem('secretariatUser', JSON.stringify(newData))
            }
        }, {immediate: true, deep: true})

        LocalStorageWrapper.watch("secretariatUser", (newSecretariatJsonStr) => {
            data.value = newSecretariatJsonStr != null ? JSON.parse(newSecretariatJsonStr) : new SecretariatUser()
        })

        function $reset() {
            data.value = new SecretariatUser()
        }

        return {data, isLoggedIn, $reset}
    })