<script setup>
const props = defineProps({
  isEnabled: {
    type: Boolean,
    required: true
  }
})
</script>

<template>
  <div :class="{'d-none': !props.isEnabled}"
       style="position: fixed; top: 0; bottom: 0; left: 0; right: 0; z-index: 10000; background-color:rgba(0,0,0, 0.2);">
    <div class="spinner-grow position-fixed" style="top: 35%; left: 50%;" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</template>

<style scoped>

</style>